@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold;
  }
}

@layer components {
  .hyperlink {
    @apply text-btnLinkText hover:text-btnLinkTextHover active:text-btnLinkTextActive disabled:text-btnLinkTextDisabled hover:enabled:underline font-semibold;
  }

  .hyperlink-muted {
    @apply text-body hover:text-body active:text-body disabled:text-btnLinkTextDisabled underline font-semibold;
  }

  .text-error {
    @apply text-alert-500;
  }

  .fill-error {
    @apply fill-alert-500;
  }

  .ring-error {
    @apply ring-1 ring-alert-500;
  }

  .default-page-width {
    /* -50px (25*2) is used to compensate for md:-mx-[25px] in AuthenticatedLayout
        which allows header cover images to go under the sidebar rounder edges */
    @apply w-full md:w-[calc(74%_-_50px)] max-w-[920px] max-md:px-8;
  }

  .default-page-width-x-padding {
    @apply w-full md:w-[74%] max-w-[980px] max-md:px-8 px-[30px];
  }

  .default-page-width-wrapper {
    @apply flex w-full flex-col items-center;
  }

  /* keeping the button animation styles here instead of a CSS module
     because it's a way to override the @tailwind base background-image:none setting */
  .button-primary-loading-bg {
    background-image: linear-gradient(90deg, #3B3E3C 0%, #3B3E3C 50%, #171817 50%, #171817 100%) !important;
    background-size: 200% 200%;
    animation: button-loading-animation 2s ease infinite;
  }

  .button-primary-loading-bg-inverted {
    background-image: linear-gradient(90deg, #D8D9D8 0%, #D8D9D8 50%, #F9FAF9 50%, #F9FAF9 100%) !important;
    background-size: 200% 200%;
    animation: button-loading-animation 2s ease infinite;
  }

  .button-secondary-loading-bg {
    background-image: linear-gradient(90deg, #EEEFEE 0%, #EEEFEE 50%, #F9FAF9 50%, #F9FAF9 100%) !important;
    background-size: 200% 200%;
    animation: button-loading-animation 2s ease infinite;
  }

  .button-secondary-loading-bg-inverted {
    background-image: linear-gradient(90deg, #3B3E3C 0%, #3B3E3C 50%, #171817 50%, #171817 100%) !important;
    background-size: 200% 200%;
    animation: button-loading-animation 2s ease infinite;
  }

  .button-destruction-loading-bg {
    background-image: linear-gradient(90deg, #FA2C28 0%, #FA2C28 50%, #C82320 50%, #C82320 100%) !important;
    background-size: 200% 200%;
    animation: button-loading-animation 2s ease infinite;
  }

  @keyframes button-loading-animation {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  .scrollbar-hide::-webkit-scrollbar {
    background: transparent;
    width: 0;
    /* Customize the scrollbar width */
  }
  
  .scrollbar-hide::-webkit-scrollbar-track {
    background: transparent;
    width: 0;
    /* Customize the scrollbar track */
  }
  
  .scrollbar-hide::-webkit-scrollbar-thumb {
    background: transparent;
    width: 0;
    /* Customize the scrollbar thumb */
  }
}
