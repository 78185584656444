@import "./globals.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Attention! Additional global styles are set in the _app.tsx file.
*/

html,
body,
#__next {
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: grid;
}

html {
  background: theme("backgroundColor.zinc.900");
}

html > * {
  /* This hides every child of html except body  */
  /* to avoid layout issues with some chrome extensions injecting elements into the html tag */
  /* like Grammarly on mobile */
  display: none;
}

body {
  /* This fores body to show even if all the child of html are hidden  */
  /* to avoid layout issues with chrome extensions injecting elements into the html tag */
  /* like Grammarly on mobile */
  display: block;
  background: theme("backgroundColor.zinc.100");
}

#__next {
  grid-template-rows: 1fr auto;
}

/* All children of body and #__next need to be fixed so that that do no interfere
with the grid layout of app containers which are the only ones having position relative */
body > *,
#__next > * {
  position: fixed;
}

#evergreen-app-container,
#evergreen-navbar {
  position: relative;
}

.font-noto {
  font-family: var(--font-noto);
}

/* CUSTOM TAILWIND UTIL CLASSES */
@layer utilities {
  .pt-safe-area {
    padding-top: env(safe-area-inset-top);
  }
  .pb-safe-area {
    padding-bottom: env(safe-area-inset-bottom);
  }
}